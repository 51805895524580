<template>
    <div id="tragos" class="drink-menu">
        <div class="image-container">
            <img :src="firstImage" alt="First Image" class="first-image" />
            <img :src="secondImage" alt="Second Image" class="second-image" />
        </div>
        <div class="drink-list-container">
            <div class="side-images">
                <img :src="images[0]" alt="Drink 1" class="side-image" />
                <img :src="images[1]" alt="Drink 2" class="side-image" />
                <img :src="images[2]" alt="Drink 3" class="side-image" />
                <img :src="images[3]" alt="Drink 4" class="side-image" />
            </div>
            <div class="drink-list-content">
                <div v-if="hasAlcoholicDrinks" class="alcohol-title">
                    CON ALCOHOL
                </div>
                <ul class="drink-list">
                    <li v-for="drink in alcoholicDrinks" :key="drink.id" :class="['drink-item', { 'last-alcoholic': isLastAlcoholic(drink.id) }]" ref="drinkItems">
                        <span class="drink-name" v-html="drink.name"></span>
                        <span class="drink-description">{{ drink.description }}</span>
                    </li>
                </ul>
                <div v-if="hasNonAlcoholicDrinks" class="non-alcohol-title">
                    SIN ALCOHOL
                </div>
                <ul class="drink-list">
                    <li v-for="drink in nonAlcoholicDrinks" :key="drink.id" class="drink-item">
                        <span class="drink-name" v-html="drink.name"></span>
                        <span class="drink-description">{{ drink.description }}</span>
                    </li>
                </ul>
            </div>
            <div class="side-images right">
                <img :src="images[4]" alt="Drink 5" class="side-image" />
                <img :src="images[5]" alt="Drink 6" class="side-image" />
                <img :src="images[6]" alt="Drink 7" class="side-image" />
                <img :src="images[7]" alt="Drink 8" class="side-image" />
            </div>
        </div>
        <div class="brand-logos">
            <img v-for="(logo, index) in brandLogos" :src="logo" :alt="'Brand Logo ' + logo" :key="index" :class="['brand-logo', { 'small-logo': index === 0 || index === 4 || index === 5, 'large-logo': index === 3 || index === 6 || index === 7 || index === 8 }]" />
        </div>
    </div>
</template>

<script>
import drink1 from '@/assets/images/drinks/1.png';
import drink2 from '@/assets/images/drinks/2.png';
import drink3 from '@/assets/images/drinks/3.png';
import drink4 from '@/assets/images/drinks/4.png';
import drink5 from '@/assets/images/drinks/5.png';
import drink6 from '@/assets/images/drinks/6.png';
import drink7 from '@/assets/images/drinks/7.png';
import drink8 from '@/assets/images/drinks/8.png';
import brandLogo1 from '@/assets/logos/brand1.png';
import brandLogo2 from '@/assets/logos/brand2.png';
import brandLogo3 from '@/assets/logos/brand3.png';
import brandLogo4 from '@/assets/logos/brand4.png';
import brandLogo5 from '@/assets/logos/brand5.png';
import brandLogo6 from '@/assets/logos/brand6.png';
import brandLogo7 from '@/assets/logos/brand7.png';
import brandLogo8 from '@/assets/logos/brand8.png';
import brandLogo9 from '@/assets/logos/brand9.png';

export default {
    data() {
        return {
            drinks: [
                {
                    id: 1,
                    name: 'Caipiroska <br/> Caipiriña <br/> Caipirisima',
                    description: 'Maracuya / Frutos Rojos / Mango / Kiwi / Frutilla',
                    alcoholic: true,
                },
                {
                    id: 2,
                    name: 'Mojito',
                    description: 'Maracuya / Frutos Rojos / Mango / Kiwi / Frutilla / Coco / Sandía',
                    alcoholic: true,
                },
                {
                    id: 3,
                    name: 'Daikiri',
                    description: 'Ananá / Frutilla',
                    alcoholic: true,
                },
                {
                    id: 4,
                    name: 'Vermouth Rooster',
                    description: 'Blanco / Rosso',
                    alcoholic: true,
                },
                {
                    id: 5,
                    name: 'Aperitivo Spritz',
                    description: '',
                    alcoholic: true,
                },
                {
                    id: 6,
                    name: 'Fernet',
                    description: '',
                    alcoholic: true,
                },
                {
                    id: 7,
                    name: 'Gin Tonic Saborizados',
                    description: '',
                    alcoholic: true,
                },
                {
                    id: 8,
                    name: 'Cuba Libre',
                    description: '',
                    alcoholic: true,
                },
                {
                    id: 9,
                    name: 'Limonadas Saborizadas',
                    description: '',
                    alcoholic: false,
                },
                {
                    id: 10,
                    name: 'Daikiri Frozen',
                    description: 'Frutilla / Ananá',
                    alcoholic: false,
                },
                {
                    id: 11,
                    name: 'Primavera Tropical Frozen',
                    description: 'Multifrutal',
                    alcoholic: false,
                },
            ],
            activeBubble: null,
            hoverIndex: null,
            firstImage: require('@/assets/images/tragos.png'),
            secondImage: require('@/assets/images/tragos_yellow.png'),
            images: [drink1, drink2, drink3, drink4, drink5, drink6, drink7, drink8],
            brandLogos: [brandLogo1, brandLogo2, brandLogo3, brandLogo4, brandLogo5, brandLogo6, brandLogo7, brandLogo8, brandLogo9],
        };
    },
    computed: {
        hasAlcoholicDrinks() {
            return this.drinks.some(drink => drink.alcoholic);
        },
        hasNonAlcoholicDrinks() {
            return this.drinks.some(drink => !drink.alcoholic);
        },
        alcoholicDrinks() {
            return this.drinks.filter(drink => drink.alcoholic);
        },
        nonAlcoholicDrinks() {
            return this.drinks.filter(drink => !drink.alcoholic);
        }
    },
    methods: {
        toggleBubble(index) {
            this.activeBubble = this.activeBubble === index ? null : index;
        },
        mouseEnter(index) {
            this.hoverIndex = index;
        },
        mouseLeave() {
            this.hoverIndex = null;
        },
        isNearRightEdge(index) {
            const item = this.$refs.drinkItems[index];
            if (item) {
                const rect = item.getBoundingClientRect();
                return rect.right > window.innerWidth - 100;
            }
            return false;
        },
        isLastAlcoholic(id) {
            const alcoholicDrinks = this.drinks.filter(drink => drink.alcoholic);
            return alcoholicDrinks[alcoholicDrinks.length - 1].id === id;
        }
    },
};
</script>

<style scoped>
.drink-menu {
    text-align: center;
    padding: 2em;
    position: relative;
    background-image:
        linear-gradient(to bottom, black, rgba(0, 0, 0, 0) 20%, rgba(0, 0, 0, 0) 80%, black),
        url('@/assets/images/drinks_bg.jpg');
    background-size: cover;
    background-position: center;
}

.drink-menu::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    z-index: 1;
}

.drink-menu>* {
    position: relative;
    z-index: 2;
}

.image-container {
    position: relative;
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
}

.first-image {
    padding-top: 7%;
    display: block;
    width: 100%;
    height: auto;
}

.second-image {
    position: absolute;
    top: 25%;
    left: 47%;
    transform: translateX(-50%);
    width: 65%;
    height: auto;
}

.drink-list-container {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    position: relative;
    z-index: 3;
}

.drink-list-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 1em;
}

.side-images {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 1em;
    position: relative;
    z-index: 3;
    padding-top: 12%;
}

.side-image {
    width: 100px;
    height: auto;
    margin: 0.5em 0;
}

.drink-list {
    list-style-type: none;
    padding: 0;
}

.drink-item {
    font-size: 1.5em;
    margin: 1em 0;
    color: #e40e7f;
}

.drink-name {
    font-weight: bold;
}

.drink-description {
    display: block;
    font-size: 0.5em;
    color: white;
}

.alcohol-title {
    font-size: 1.2em;
    color: white;
    margin: 4em 0 0.2em 0;
}

.non-alcohol-title {
    font-size: 1.2em;
    color: white;
    margin: 1em 0 0.2em 0;
}

.last-alcoholic {
    border-bottom: 2px dotted white;
    padding-bottom: 0.5em;
}

.brand-logos {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 8em;
    position: relative;
    bottom: 0;
    width: 100%;
    padding-bottom: 8em;
    z-index: 1;
}

.brand-logo {
    width: auto;
    height: 90px;
    margin: 0.5em;
}

.small-logo {
    height: 60px;
}

.large-logo {
    height: 100px;
}

@media (max-width: 1200px) {
    .side-image {
        width: 80px;
    }

    .brand-logo {
        height: 70px;
    }

    .small-logo {
        height: 60px;
    }

    .large-logo {
        height: 100px;
    }
}

@media (max-width: 768px) {
    .side-images {
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        margin-top: 2em; /* Add margin-top for spacing */
    }

    .side-image {
        width: 60px;
        margin: 1em; /* Increase margin for more spacing */
    }

    .brand-logo {
        height: 50px;
    }

    .small-logo {
        height: 40px;
    }

    .large-logo {
        height: 80px;
    }

    #drink-menu-section {
        padding-top: 4em;
    }

    .side-images.right {
        padding-top: 20%; /* Move the right side images lower */
    }
}

@media (max-width: 480px) {
    .side-images {
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        margin-top: 2em; /* Add margin-top for spacing */
    }

    .side-image {
        width: 40px;
        margin: 1.5em; /* Further increase margin for more spacing */
    }

    .brand-logo {
        height: 40px;
    }

    .small-logo {
        height: 30px;
    }

    .large-logo {
        height: 60px;
    }

    .side-images.right {
        padding-top: 120%; /* Move the right side images even lower */
    }
}
</style>